@charset "UTF-8";
/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.tenant-wbw .c-hero-component .download-offer {
  margin: 30px 0;
}
.tenant-wbw .c-hero-component .download-offer-calendar--button:focus {
  text-decoration: underline;
}
.tenant-wbw .c-hero-component .download-offer > div:first-child {
  margin-bottom: 25px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .download-offer > div:first-child {
    margin-bottom: 0;
  }
}
.tenant-wbw .c-hero-component .download-offer-calendar {
  /* Small devices (tablets, 768px and up) */
}
.tenant-wbw .c-hero-component .download-offer-calendar a {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 14px;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .download-offer-calendar a {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .download-offer-calendar a {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .download-offer-calendar a {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .download-offer-calendar {
    padding-left: 45px;
  }
}
.tenant-wbw .c-hero-component .download-offer .labelText {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 14px;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .download-offer .labelText {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .download-offer .labelText {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .download-offer .labelText {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.tenant-wbw .c-hero-component .download-offer .addeventatc {
  width: auto;
}
.tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown {
  left: -50% !important;
  border: 1px solid #d3d0d0;
  padding: 7px 0;
}
.tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  border-bottom: 1px solid #d3d0d0;
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span:hover, .tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown span:focus {
  color: #999;
}
.tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown:after {
  display: none;
}
.tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown:before {
  margin-left: 0;
  width: 15px;
  height: 15px;
  border-radius: 0 0 6px 0;
  -webkit-transform: rotate(225deg);
  -khtml-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
  background: linear-gradient(-45deg, #fff 50%, #fff 50%);
  border: 1px solid #d3d0d0;
  border-top: none;
  border-left: none;
  top: -8px;
}
.tenant-wbw .c-hero-component .hero-banner-container {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .hero-banner-container {
    position: relative;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-banner-container {
    position: relative;
    height: 300px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-banner-container {
    height: 400px;
  }
}
.tenant-wbw .c-hero-component .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-banner-container:before {
    content: " ";
    position: absolute;
    width: 100%;
    background: #ffcb29;
    left: 0;
    height: 300px;
    z-index: -1;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-banner-container:before {
    height: 400px;
  }
}
.tenant-wbw .c-hero-component .hero-image {
  height: auto;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-image {
    height: 309px;
    position: relative;
    overflow: hidden;
  }
  .tenant-wbw .c-hero-component .hero-image img {
    width: auto;
    height: 100%;
    position: absolute;
    max-width: none;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-image {
    height: 412px;
  }
}
.tenant-wbw .c-hero-component .hero-detail-container {
  margin: auto;
  top: 24px;
  text-align: left;
  color: #fff;
  z-index: 2;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .hero-detail-container {
    top: 50%;
    transform: translateY(-50%);
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-detail-container {
    max-width: 40%;
    padding: 0 145px;
    margin: 0;
  }
}
.tenant-wbw .c-hero-component .hero-detail-container .hero-banner-title {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.08;
  letter-spacing: -0.2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.15;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.27;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-detail-container .hero-banner-title {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-detail-container .hero-banner-title {
    line-height: 52px;
  }
}
.tenant-wbw .c-hero-component .hero-detail-container .hero-banner-desc {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.2;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-detail-container .hero-banner-desc {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33;
  }
}
.tenant-wbw .c-hero-component .hero-detail-container h1.hero-banner-shortTitle {
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-size: 26px;
  font-size: 1.625rem;
  letter-spacing: -0.2px;
  line-height: 1.15;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .hero-detail-container h1.hero-banner-shortTitle {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-detail-container h1.hero-banner-shortTitle {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.25;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-detail-container h1.hero-banner-shortTitle {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1.12;
  }
}
.tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.22;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
    letter-spacing: 0;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.2;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media only screen and (min-width: 1366px) and (min-width: 768px) {
  .tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1366px) and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 1366px) and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .hero-detail-container h2.hero-banner-shortTitle {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper {
  border-bottom: 1px solid #d3d0d0;
  width: 95.2380952381%;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-top: 30px;
  padding-bottom: 25px;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper + .editorial-text {
  margin-top: 40px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper + .editorial-text {
    margin-top: 55px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper {
    border-bottom: none;
    width: 89.8484848485%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper {
    max-width: 1366px;
    width: 81.5151515152%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper {
    margin-top: 40px;
    padding-bottom: 0;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: center;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  margin-top: 30px;
  display: block;
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible {
  position: relative;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .more-content-visible:before {
  content: "";
  position: absolute;
  right: 39%;
  top: calc(50% - 5px);
  height: 7px;
  width: 7px;
  border-right: 1px #333 solid;
  border-bottom: 1px #333 solid;
  transform: rotate(45deg);
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .more-content-hidden {
  display: none;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon {
  width: 95.2380952381%;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  flex-wrap: wrap;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon {
    width: 97.311827957%;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon {
    width: 98.1818181818%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    flex-wrap: nowrap;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip {
  width: 45.2380952381%;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  text-align: center;
  margin-bottom: 15px;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip {
    width: 22.311827957%;
    padding-left: 1.3440860215%;
    padding-right: 1.3440860215%;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip {
    border-right: 1px dotted #d3d0d0;
    width: 18.1818181818%;
    padding-left: 0.9090909091%;
    padding-right: 0.9090909091%;
  }
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:last-child {
    border: none;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip img {
  width: 60px;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip {
    margin-bottom: 0;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .short-title {
  display: block;
  padding-top: 24px;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .short-title .body-copy-4 {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 14px;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: center;
  font-family: "Open Sans";
  font-weight: 700;
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .short-title .body-copy-4 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .short-title .body-copy-4 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .short-title .body-copy-4 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .short-title .body-copy-4.desc {
  font-weight: 400;
  margin-top: 5px;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip {
  margin: 0;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .close-tooltip {
  position: absolute;
  top: 7px;
  right: 9px;
  cursor: pointer;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .close-tooltip {
    display: none;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .close-tooltip svg.icn-close {
  width: 10px;
  height: 10px;
  stroke: #333;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .body-copy-4 {
  display: inline-block;
  /* Medium devices (desktops, 1024px and up) */
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.22;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .body-copy-4 {
    top: 16%;
  }
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .body-copy-4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .header-bottom-profile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .header-bottom-profile {
    margin: 0 4px;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .header-bottom-profile-name {
  margin-top: 8px;
  padding: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .editorial-grid-tile-icon {
  width: 60px;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .arrow-icon {
  position: absolute;
  left: 50%;
  margin-left: -3px;
  bottom: -12px;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .arrow-icon:after {
  content: "";
  border: 1px solid #000;
  border-bottom-width: 0;
  border-left-width: 0;
  position: absolute;
  width: 7px;
  height: 7px;
  transform: rotate(-224deg);
  bottom: 0;
}
.tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .tooltipOpen .arrow-icon {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .tooltipOpen .arrow-icon:before {
    border-top: none;
  }
}
.tenant-wbw .c-hero-component .editorial-text {
  clear: both;
  text-align: center;
  margin: 30px auto;
  width: 100%;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text {
    margin: 40px auto;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-text {
    max-width: 1366px;
    margin: 40px auto;
  }
}
.tenant-wbw .c-hero-component .editorial-text .w--content {
  margin-top: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text .w--content {
    margin-bottom: 20px;
  }
}
.tenant-wbw .c-hero-component .editorial-text .w--content h2 {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.08;
  letter-spacing: -0.2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-text .w--content h2 {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.15;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text .w--content h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.27;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-text .w--content h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.1;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text .w--content h2 {
    margin-top: 0;
    margin-bottom: 16px;
  }
}
.tenant-wbw .c-hero-component .editorial-text .w--content + h3 {
  margin-bottom: 25px;
}
.tenant-wbw .c-hero-component .editorial-text .c-editorial-text.component {
  max-width: none;
  margin: auto;
}
.tenant-wbw .c-hero-component .editorial-text .c-editorial-text.component .w--content.from-hero-component-container > h2:before,
.tenant-wbw .c-hero-component .editorial-text .c-editorial-text.component .w--content.from-hero-component-container > h1:before {
  content: "";
  display: block;
  border-left: 2px solid;
  height: 24px;
  margin-left: 50%;
  margin-bottom: 5px;
}
.tenant-wbw .c-hero-component .editorial-text .body-copy-1 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-text .body-copy-1 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.tenant-wbw .c-hero-component .editorial-text button {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: center;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-text button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-text button {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.tenant-wbw .c-hero-component .editorial-text .hero-cta-button {
  display: inline-block;
  margin: 25px 0 27px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text .hero-cta-button {
    margin: 36px 0 27px;
  }
}
.tenant-wbw .c-hero-component .editorial-text .hero-cta-button a {
  color: #333333;
  font-size: 12px;
  line-height: 21.96px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text .hero-cta-button a {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.tenant-wbw .c-hero-component .editorial-text .ride-info {
  cursor: pointer;
  font: normal normal 400 12px "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  /* Small devices (tablets, 768px and up) */
}
.tenant-wbw .c-hero-component .editorial-text .ride-info button {
  padding: 0;
  border-style: none;
  line-height: 1.6;
  letter-spacing: 1.9px;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-text .ride-info {
    font-size: 13px;
    letter-spacing: 0;
  }
}
.tenant-wbw .c-hero-component .editorial-text .CTA-button {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.22;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  cursor: pointer;
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component .editorial-text .CTA-button {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text .CTA-button {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component .editorial-text .CTA-button {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.tenant-wbw .c-hero-component .editorial-text li {
  list-style-position: inside;
}
.tenant-wbw .c-hero-component .editorial-text.from-hero-component-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .editorial-text.from-hero-component-container {
    margin-top: 30px;
  }
}
.tenant-wbw .c-hero-component.left .hero-banner-title,
.tenant-wbw .c-hero-component.left .hero-banner-desc,
.tenant-wbw .c-hero-component.left .hero-banner-shortTitle {
  text-align: left;
}
.tenant-wbw .c-hero-component.center .hero-banner-title,
.tenant-wbw .c-hero-component.center .hero-banner-desc,
.tenant-wbw .c-hero-component.center .hero-banner-shortTitle {
  text-align: center;
}
.tenant-wbw .c-hero-component.right .hero-banner-title,
.tenant-wbw .c-hero-component.right .hero-banner-desc,
.tenant-wbw .c-hero-component.right .hero-banner-shortTitle {
  text-align: right;
}
.tenant-wbw .c-hero-component.right .w--content {
  left: auto;
}
.tenant-wbw .c-hero-component.light .hero-banner-title,
.tenant-wbw .c-hero-component.light .hero-banner-desc,
.tenant-wbw .c-hero-component.light .hero-banner-shortTitle {
  color: #fff;
}
.tenant-wbw .c-hero-component.dark .hero-banner-title,
.tenant-wbw .c-hero-component.dark .hero-banner-desc,
.tenant-wbw .c-hero-component.dark .hero-banner-shortTitle {
  color: #333;
}
.tenant-wbw .c-hero-component.v-gradient-filter .hero-image picture:after {
  content: "";
  display: block;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  z-index: 1;
  left: 0;
  max-width: none;
  max-height: none;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component.v-gradient-filter .hero-image picture:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.v-gradient-filter .hero-image picture:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
.tenant-wbw .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container {
  max-width: initial;
  height: auto;
}
.tenant-wbw .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
  content: "";
  display: block;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: 0;
  z-index: 1;
  left: 0;
  max-width: none;
  max-height: none;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.v-gradient-filter.hero-carousel .c-media-carousel .c-media-carousel-view-container:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view {
  /* < 1023px */
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view.dom-rendering .c-media-carousel-wrapper {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view.dom-rendering .c-media-carousel-wrapper {
    background-color: #333;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view.dom-rendering .c-media-carousel-wrapper .media-carousel--content .w-media-content {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view.dom-rendering .c-media-carousel-wrapper .media-carousel--content .w-media-content {
    margin-bottom: 65px;
  }
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view {
    transition: all 0.5s;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper {
  margin-top: 0;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .w--content {
  max-width: 100%;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel {
  margin-top: 0;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle {
  margin-top: 0;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle.dom-pending .slick-initialized .slick-list .slick-track .slick-slide[data-index="-1"] {
  display: none;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container {
  max-width: 100%;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized {
    background: #333;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track {
  max-width: none;
  display: flex;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide > div {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .slick-slide > div {
    height: 720px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item {
  position: relative;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item {
    height: 720px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--image {
  height: 100%;
  position: relative;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--image {
    height: 250px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
  display: block;
  /* Medium devices (desktops, 1024px and up) */
  /* < 1023px */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
    width: 400px;
    position: absolute;
    top: 150px;
    text-align: left;
    margin-left: 120px;
    padding: 30px 0;
  }
  .lang-ar .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
    text-align: right;
    margin-left: 0;
    margin-right: 120px;
  }
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
    height: calc(100% - 250px);
    text-align: center;
    padding: 25px 15px 30px;
  }
}
.lang-ar .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container {
    text-align: right;
    margin-left: 0;
    margin-right: 120px;
    right: 0;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--title {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 2.5rem;
  line-height: 1.1;
  color: #fff;
  padding-bottom: 10px;
  letter-spacing: normal;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--title {
    text-transform: uppercase;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
  margin-top: 0;
  margin-left: 3px;
  max-width: 100%;
  color: #fff;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  letter-spacing: 0.1px;
  margin-bottom: 17px;
  font-size: 0.875rem;
  line-height: 1.71;
}
.lang-ar .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
    text-align: right;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .btn-primary.button-wrapper {
  margin-top: 26px;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .btn-primary.button-wrapper a {
  padding: 15px 20px 14px;
  line-height: 14px;
  font-size: 13px;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .btn-primary.button-wrapper a:before {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .btn-primary.button-wrapper a:before {
    background-color: #000;
    border: 1px solid #fff;
  }
}
.lang-ar .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-desc-container .btn-primary.button-wrapper {
  padding: 0;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--media-video .c-video {
  margin-top: 0;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--media-video .c-video .c-video-player {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--media-video .c-video .c-video-player {
    min-height: 250px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--media-video .c-video .c-video-player video {
  width: 100%;
  object-fit: cover;
  pointer-events: auto !important;
  visibility: visible;
  /* Medium devices (desktops, 1024px and up) */
  /* < 1023px */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--media-video .c-video .c-video-player video {
    height: 720px;
  }
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-list .slick-track .media-carousel-item .media-carousel-item--media-video .c-video .c-video-player video {
    height: 250px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow {
  width: 30px;
  height: 30px;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow::after {
  width: 8px;
  height: 8px;
  inset: 0;
  margin: auto;
  border-color: #fff;
  border-style: solid;
  border-width: 2px 2px 0 0;
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow {
    top: 230px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow.slick-next {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow.slick-next {
    right: 35px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow.slick-next:after {
  left: -3px;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow.slick-prev {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow.slick-prev {
    left: 35px;
  }
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow.slick-prev:after {
  right: -3px;
}
.tenant-wbw .c-hero-component.smart-hero-carousel-view .c-media-carousel-wrapper .media-carousel--content .c-media-carousel .w--middle .v-carousel-view-container .slick-initialized .slick-arrow.slick-disabled {
  opacity: 0.3;
  cursor: default;
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content {
  position: relative;
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .quick-links {
  bottom: 75px;
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container {
  /* Small devices (tablets, 768px and up) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container {
    width: 100%;
    background-color: #333;
    max-width: 100%;
    padding: 25px 15px 30px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container {
    width: 100%;
    background-color: #333;
    max-width: 100%;
    padding: 25px 15px 30px;
  }
}
.lang-ar .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container {
    text-align: right;
    margin-left: 0;
    margin-right: 120px;
    right: 0;
  }
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container h1.media-carousel-item--title {
  /* Small devices (tablets, 768px and up) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container h1.media-carousel-item--title {
    font-size: 30px;
    line-height: 38px;
    text-transform: uppercase;
    font-family: GothamBold, Open Sans, Arial, sans-serif;
    color: #ffffff;
    letter-spacing: 0;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container h1.media-carousel-item--title {
    font-size: 30px;
    line-height: 38px;
    text-transform: uppercase;
    font-family: GothamBold, Open Sans, Arial, sans-serif;
    color: #ffffff;
    letter-spacing: 0;
  }
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
  /* Small devices (tablets, 768px and up) */
  /* < 768px */
}
@media (min-width: 768px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
    color: #ffffff;
    margin: 0;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .media-carousel-item--bodycopy {
    color: #ffffff;
    margin: 0;
  }
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .btn-primary {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .btn-primary {
    margin-left: 3px;
  }
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .btn-primary a {
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 14px;
  padding: 15px 20px 14px;
  font-family: GothamBlack, sans-serif;
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .btn-primary a::before {
  /* Medium devices (desktops, 1024px and up) */
  /* Small devices (tablets, 768px and up) */
  /* < 768px */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .btn-primary a::before {
    background: #fff;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .btn-primary a::before {
    background-color: #000;
    border: 1px solid #fff;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .btn-primary a::before {
    background-color: #000;
    border: 1px solid #fff;
  }
}
.tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .media-carousel-item--title {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .tenant-wbw .c-hero-component .c-media-carousel-wrapper .media-carousel--content .media-carousel-item .media-desc-container .media-carousel-item--title {
    color: #fff;
  }
}
.tenant-wbw .c-hero-component .quick-links {
  position: absolute;
  text-align: center;
  width: 100%;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .c-hero-component .quick-links {
    position: relative;
    padding: 10px;
    background-color: #333333;
    top: -30px;
    margin-bottom: -30px;
  }
}
.tenant-wbw .c-hero-component .quick-links .quick-links-band {
  background: #fff;
  border-color: #d3d3d3;
}
.tenant-wbw .c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  line-height: 32px;
  color: #333333;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-wbw .c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
    font-size: 24px;
    line-height: 23px;
  }
}
.lang-ar .tenant-wbw .c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
  font-weight: 700;
  line-height: 40px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .tenant-wbw .c-hero-component .quick-links .quick-links-band-item__link__content__text span.ldc-custom {
    line-height: 30px;
  }
}
.tenant-wbw .c-hero-component .quick-links .quick-links-band-item__link__content__text span.short-title {
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
}
.lang-ar .tenant-wbw .c-hero-component .quick-links .quick-links-band-item__link__content__text span.short-title {
  line-height: 30px;
}

.tenant-wbw .c-hero-component.v-annual-pass-hero .hero-detail-container h2.hero-banner-shortTitle {
  font-family: "Open Sans", Arial, sans-serif;
  letter-spacing: 2px;
  line-height: 1.25;
  font-weight: normal;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.v-annual-pass-hero .hero-detail-container h2.hero-banner-shortTitle {
    letter-spacing: 1.5px;
    line-height: 1.05;
  }
}
.tenant-wbw .c-hero-component.v-annual-pass-hero .hero-detail-container h2.hero-banner-shortTitle > div {
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-size: 26px;
  font-size: 1.625rem;
  letter-spacing: -0.2px;
  line-height: 1.15;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-wbw .c-hero-component.v-annual-pass-hero .hero-detail-container h2.hero-banner-shortTitle > div {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-wbw .c-hero-component.v-annual-pass-hero .hero-detail-container h2.hero-banner-shortTitle > div {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.25;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-wbw .c-hero-component.v-annual-pass-hero .hero-detail-container h2.hero-banner-shortTitle > div {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 1.12;
  }
}

.ride-info-overlay {
  background: #f6f6f6;
}
.ride-info-overlay .overlay-wrapper {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  border-top: 1px solid rgba(0, 0, 0, 0.7);
  text-align: center;
  overflow: auto;
  padding: 50px 20px;
  display: block;
  height: 100%;
  overflow-x: hidden;
  border-radius: 4px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay .overlay-wrapper {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper {
    padding: 80px;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper {
    padding: 50px 80px 25px;
    max-height: 500px;
    width: 64.8484848485%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    max-width: 885.8303px;
    min-height: auto;
  }
}
.ride-info-overlay .overlay-wrapper::-webkit-scrollbar {
  width: 0;
}
.ride-info-overlay .overlay-wrapper div:not(.brand-logo).brand-logo-container {
  width: auto;
  display: none;
}
.ride-info-overlay .overlay-wrapper div.overlay--close-button {
  top: 27px;
}
.ride-info-overlay .overlay-wrapper div.overlay--close-button svg use.svg-use {
  fill: #333;
  stroke: #333;
}
.ride-info-overlay .overlay-wrapper:before {
  background-color: #f6f6f6;
  border-radius: 0 0 90px 90px;
  content: "";
  display: block;
  height: 18px;
  left: 50%;
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  width: 24px;
}
.ride-info-overlay .overlay-wrapper h1 {
  padding-bottom: 15px;
  padding-top: 20px;
}
.ride-info-overlay .overlay-wrapper h2 {
  padding-bottom: 20px;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 14px;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper h2 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper h2 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay .overlay-wrapper h2 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper h2 {
    padding-bottom: 10px;
  }
}
.ride-info-overlay .overlay-wrapper ul {
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
}
.ride-info-overlay .overlay-wrapper p {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: left;
  padding-bottom: 20px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .ride-info-overlay .overlay-wrapper p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 24px;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay .overlay-wrapper p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
.ride-info-overlay .overlay-wrapper .overlay-sub-heading {
  left: 0;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.22;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  position: fixed;
  top: 30px;
  width: 100%;
  font-weight: 700;
}
.lang-ar .ride-info-overlay .overlay-wrapper .overlay-sub-heading {
  right: 0;
  left: auto;
  left: auto;
}
@media (min-width: 768px) {
  .ride-info-overlay .overlay-wrapper .overlay-sub-heading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay .overlay-wrapper .overlay-sub-heading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay .overlay-wrapper .overlay-sub-heading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.ride-info-overlay .overlay-wrapper .overlay-description {
  max-width: 100%;
  padding-bottom: 0;
}
.ride-info-overlay .overlay-wrapper .overlay-description li {
  margin-left: 20px;
}
.ride-info-overlay .overlay-wrapper .overlay-content-container {
  padding: 0 5px;
}
.ride-info-overlay .overlay-wrapper .top-border::before, .ride-info-overlay .overlay-wrapper .top-border::after {
  height: 21px;
  top: -10px;
}
.ride-info-overlay .overlay-wrapper .top-border .circle {
  background-color: rgba(0, 0, 0, 0.7);
  top: -23px;
}
.ride-info-overlay h3 {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .ride-info-overlay h3 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .ride-info-overlay h3 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .ride-info-overlay h3 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.ride-info-overlay .w--middle {
  width: 100%;
}

body.cartoon .tenant-wbw .c-hero-component.component {
  margin-bottom: 0;
}
body.cartoon .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper + .editorial-text {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.cartoon .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper + .editorial-text {
    margin: 50px auto;
  }
}
body.cartoon .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper + .editorial-text .w--content {
  margin-bottom: 0;
}
body.cartoon .c-tabs-with-placeholder .v-tabs-dropdown {
  margin-top: 0;
}

body.explore .c-editorial-text {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.explore .c-editorial-text .w--content {
    margin: 55px auto 35px;
  }
}
body.explore .c-tabs-with-placeholder .v-tabs-dropdown {
  margin-top: 0;
}

body.homepage .hero-banner-container:before,
body.legal .hero-banner-container:before,
body.discover_yas .hero-banner-container:before,
body.contact_us .hero-banner-container:before,
body.media .hero-banner-container:before,
body.park_overview .hero-banner-container:before,
body.faq .hero-banner-container:before,
body.not_found .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.homepage .hero-banner-container:before,
  body.legal .hero-banner-container:before,
  body.discover_yas .hero-banner-container:before,
  body.contact_us .hero-banner-container:before,
  body.media .hero-banner-container:before,
  body.park_overview .hero-banner-container:before,
  body.faq .hero-banner-container:before,
  body.not_found .hero-banner-container:before {
    background: #ffcb29;
  }
}

body.metropolis .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.metropolis .hero-banner-container:before {
    background: #055fb2;
  }
}

body.gotham .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.gotham .hero-banner-container:before {
    background: #2f383a;
  }
}

body.cartoon .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.cartoon .hero-banner-container:before {
    background: #90699a;
  }
}

body.dynamite .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.dynamite .hero-banner-container:before {
    background: #cf7e61;
  }
}

body.bedrock .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.bedrock .hero-banner-container:before {
    background: #fa5e32;
  }
}

body.warner-plaza .hero-banner-container:before {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  body.warner-plaza .hero-banner-container:before {
    background: #408b7e;
  }
}

.blog-detail .editorial-text {
  text-align: left;
}
.blog-detail .editorial-text .w--content {
  text-align: left;
}
.blog-detail .editorial-text .w--content h2 {
  margin-bottom: 4px;
}
.blog-detail .editorial-text .big-para {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .blog-detail .editorial-text .big-para {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .blog-detail .editorial-text .big-para {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1366px) {
  .blog-detail .editorial-text .big-para {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.67;
  }
}
.blog-detail .editorial-text .article-date {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .blog-detail .editorial-text .article-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .blog-detail .editorial-text .article-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .blog-detail .editorial-text .article-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.blog-detail .editorial-text p {
  margin-bottom: 20px;
}
.blog-detail .big-font {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .blog-detail .big-font {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .blog-detail .big-font {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1366px) {
  .blog-detail .big-font {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.67;
  }
}

.block-quote {
  display: block;
  margin: 30px 0;
  padding: 0 0 0 60px;
  quotes: "“" "”";
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.2;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  position: relative;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .block-quote {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .block-quote {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 1366px) {
  .block-quote {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33;
  }
}
@media only screen and (min-width: 1024px) {
  .block-quote {
    padding: 0 100px;
  }
}
.block-quote:before {
  color: #ffdf00;
  content: open-quote;
  font-size: 100px;
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  line-height: 0;
  position: absolute;
  top: 19px;
  left: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .block-quote:before {
    top: 24px;
    left: 28px;
  }
}

.lang-ar .ride-info-overlay {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .ride-info-overlay .overlay-wrapper .overlay-content-inner-container {
  left: auto;
  right: 22px;
}
.lang-ar .ride-info-overlay .overlay-wrapper .overlay--close-button {
  right: auto;
  left: 20px;
}
.lang-ar .ride-info-overlay .overlay-wrapper ul,
.lang-ar .ride-info-overlay .overlay-wrapper p {
  text-align: right;
}
.lang-ar .ride-info-overlay .overlay-wrapper .overlay-description {
  direction: rtl;
}
.lang-ar .ride-info-overlay .overlay-wrapper .overlay-description li {
  direction: rtl;
  margin-right: 20px;
}
.lang-ar .tenant-wbw .c-hero-component .download-offer {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .tenant-wbw .c-hero-component .download-offer .download-offer-download {
    text-align: left;
  }
  .lang-ar .tenant-wbw .c-hero-component .download-offer .download-offer-calendar {
    text-align: right;
  }
}
.lang-ar .tenant-wbw .c-hero-component .download-offer .addeventatc_dropdown {
  text-align: right;
}
.lang-ar .tenant-wbw .c-hero-component .download-offer .c-icon {
  margin-right: 5px;
}
.lang-ar .tenant-wbw .c-hero-component .download-offer-calendar--icon {
  margin-right: 8px;
}
.lang-ar .tenant-wbw .c-hero-component .ride-info:after {
  top: 0;
  font-weight: 700;
}
.lang-ar .tenant-wbw .c-hero-component .hero-banner-title {
  padding-bottom: 3px;
}
.lang-ar .tenant-wbw .c-hero-component .title,
.lang-ar .tenant-wbw .c-hero-component .hero-banner-title,
.lang-ar .tenant-wbw .c-hero-component .hero-banner-desc,
.lang-ar .tenant-wbw .c-hero-component .hero-banner-shortTitle {
  text-align: right;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .close-tooltip {
  right: auto;
  left: 12px;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .product-tooltip .body-copy-4 {
  display: inline-block;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .arrow-icon {
  position: absolute;
  left: auto;
  margin-right: auto;
  bottom: -12px;
  right: 26px;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .arrow-icon::before {
  content: "";
  width: 9px;
  position: absolute;
  bottom: 4px;
  left: -8px;
  right: auto;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .arrow-icon::after {
  right: 0;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip {
  /* Medium devices (desktops, 1024px and up) */
  /* < 768px */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip {
    border-right: none;
    border-left: 1px dotted #d3d0d0;
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:last-child {
    border: none;
  }
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .product-tooltip-body .header-bottom-profile-name {
  margin-left: 0;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .product-tooltip-body ul {
  margin-left: 0;
  margin-right: 18px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(even) .product-tooltip-body {
    transform: translateX(-28%);
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(even) .product-tooltip-body:before {
    left: 23.8%;
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(odd) .product-tooltip-body {
    transform: translateX(-73%);
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(odd) .product-tooltip-body:before {
    left: 69%;
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(odd):last-child .product-tooltip-body {
    transform: translateX(-50%);
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(odd):last-child .product-tooltip-body:before {
    left: calc(50% - 11px);
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .product-tooltip-body {
    transform: translateX(-61%);
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip .product-tooltip-body:before {
    left: 58%;
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(4n) .product-tooltip-body {
    transform: translateX(-40%);
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-grid-tooltip-wrapper .editorial-grid-icon .c-editorial-grid-tooltip:nth-child(4n) .product-tooltip-body:before {
    left: 36.7%;
  }
}
.lang-ar .tenant-wbw .c-hero-component .w--content {
  margin: 0;
}
.lang-ar .tenant-wbw .c-hero-component .hero-card-container {
  margin: 25px auto auto;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content {
  margin: 0 auto;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container h1,
.lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container h2 {
  font-size: 24px;
  line-height: 28px !important;
  font-weight: 700;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container h1,
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container h2 {
    font-size: 28px;
    line-height: 40px !important;
  }
}
.lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container .body-copy-1 h2,
.lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container .body-copy-1 h3 {
  font-size: 16px;
  font-weight: unset;
  font-weight: 700;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container .body-copy-1 h2,
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .c-editorial-text .w--content.from-hero-component-container .body-copy-1 h3 {
    font-size: 18px;
  }
}
.lang-ar .tenant-wbw .c-hero-component .editorial-text .ride-info {
  font-weight: 700;
}
.lang-ar .tenant-wbw .c-hero-component .editorial-text .hero-cta-button a {
  padding-top: 9px;
  padding-bottom: 9px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .hero-cta-button a {
    font-size: 12px;
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .hero-cta-button a::before {
    top: 7px;
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .hero-cta-button a:hover {
    top: 2px;
    right: 0;
  }
  .lang-ar .tenant-wbw .c-hero-component .editorial-text .hero-cta-button a:hover::before {
    right: 9px;
  }
}
.lang-ar .tenant-wbw .c-hero-component.v-gradient-filter .hero-image picture:after {
  transform: scaleX(-1);
}

/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.Home .hero-panel-with-card {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .Home .hero-panel-with-card {
    height: 404.8px;
  }
  .Home .hero-panel-with-card .hero-card-container {
    padding-top: 480px;
  }
}
@media only screen and (min-width: 1366px) {
  .Home .hero-panel-with-card {
    height: 540px;
  }
  .Home .hero-panel-with-card .hero-card-container {
    padding-top: 480px;
  }
}
.Home .hero-panel-with-card .hero-banner-container {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .Home .hero-panel-with-card .hero-banner-container {
    height: 391px;
    background-color: #ffcb29;
  }
}
@media only screen and (min-width: 1366px) {
  .Home .hero-panel-with-card .hero-banner-container {
    height: 521px;
  }
}
.Home .hero-panel-with-card .hero-banner-container .hero-image {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .Home .hero-panel-with-card .hero-banner-container .hero-image {
    height: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .Home .hero-panel-with-card .hero-banner-container .hero-image {
    height: 404.8px;
  }
}
@media only screen and (min-width: 1366px) {
  .Home .hero-panel-with-card .hero-banner-container .hero-image {
    height: 540px;
  }
}

.hero-panel-with-card {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /*
          Hero card component css
      */
}
.hero-panel-with-card.component {
  margin-bottom: 0;
}
.hero-panel-with-card.hero-panel-contain-card {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card {
    margin-bottom: 300px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card {
    height: 309px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card {
    height: 412px;
  }
}
.hero-panel-with-card .hero-banner-container {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card .hero-banner-container {
    position: absolute;
    height: 301px;
    left: 0;
    right: 0;
    background-color: #ffcb29;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card .hero-banner-container {
    height: 401px;
  }
}
.hero-panel-with-card .hero-image {
  text-align: center;
  overflow: hidden;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-image {
    height: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card .hero-image {
    height: 309px;
    position: relative;
  }
  .hero-panel-with-card .hero-image img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card .hero-image {
    height: 412px;
  }
}
.hero-panel-with-card .hero-image img {
  width: 100%;
  max-width: 100%;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card .hero-image img {
    height: 100%;
    width: auto;
    max-width: 100%;
    max-width: none;
  }
}
.hero-panel-with-card .hero-detail-container {
  left: 0;
  right: 0;
  top: 24px;
  margin: 0 auto;
  text-align: left;
  color: #333;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-detail-container {
    top: 50%;
    transform: translateY(-50%);
  }
}
.hero-panel-with-card .hero-detail-container .contents > .title h2 {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.2;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-detail-container .contents > .title h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card .hero-detail-container .contents > .title h2 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.3;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card .hero-detail-container .contents > .title h2 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.33;
  }
}
.legal__page__donot__touch .hero-panel-with-card .hero-detail-container .contents > .title {
  color: #131313;
}
.hero-panel-with-card .hero-detail-container .contents > :last-child {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-detail-container .contents > :last-child {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card .hero-detail-container .contents > :last-child {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card .hero-detail-container .contents > :last-child {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.hero-panel-with-card .hero-detail-container .contents > :last-child h1 sup {
  font-size: 18px;
  top: 0.15em;
  vertical-align: super;
}
@media (min-width: 1024px) {
  .hero-panel-with-card .hero-detail-container .contents > :last-child h1 sup {
    top: -0.15em;
  }
}
@media (min-width: 1366px) {
  .hero-panel-with-card .hero-detail-container .contents > :last-child h1 sup {
    top: -0.25em;
  }
}
.legal__page__donot__touch .hero-panel-with-card .hero-detail-container .contents > :last-child {
  color: #191717;
}
.hero-panel-with-card.center div.hero-detail-container {
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
.hero-panel-with-card.light .hero-detail-container {
  color: #fff;
}
.hero-panel-with-card div.btn-primary {
  padding-top: 24px;
}
.hero-panel-with-card div.btn-primary a::before {
  background-color: #fff;
}
.hero-panel-with-card div.btn-primary:hover a:before {
  background-color: #ffdf00;
}
.hero-panel-with-card .hero-banner-title {
  margin-bottom: 4px;
  font-weight: 800;
  /* Small devices (tablets, 768px and up) */
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-banner-title {
    margin-bottom: 3px;
  }
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-banner-title {
    margin-bottom: 0;
  }
}
.hero-panel-with-card .hero-card-container {
  margin-top: -60px;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card .hero-card-container {
    padding-top: 352px;
    margin-top: 0;
  }
}
.hero-panel-with-card .hero-card-container .hero-card {
  border-radius: 4px;
  background-color: #f6f6f6;
}
.hero-panel-with-card .hero-card-container .card-content .card-title {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #252222;
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-card-container .card-content .card-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card .hero-card-container .card-content .card-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card .hero-card-container .card-content .card-title {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.lang-ru .hero-panel-with-card .hero-card-container .card-content .card-title {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
}
.lang-zh .hero-panel-with-card .hero-card-container .card-content .card-title {
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 2px;
  line-height: 14px;
  font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  font-weight: bold;
}
.hero-panel-with-card .hero-card-container .card-content .card-body {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #333;
}
@media (min-width: 768px) {
  .hero-panel-with-card .hero-card-container .card-content .card-body {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card .hero-card-container .card-content .card-body {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card .hero-card-container .card-content .card-body {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.hero-panel-with-card.hero-panel-contain-card {
  height: auto;
  margin-bottom: 0;
}
.hero-panel-with-card.hero-panel-contain-card .hero-banner-container {
  position: relative;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container {
  padding-top: 0;
  max-width: 100%;
  margin-top: 10px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container {
    width: 98.1818181818%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 10px auto auto;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container {
    max-width: 1366px;
    margin: 25px auto auto;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
  width: 100%;
  background: none;
  margin: 0;
  position: relative;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card {
    width: 48.1818181818%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    float: left;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-image {
  float: none;
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
  width: 86.9047619048%;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  padding: 24px 17px 28px 18px;
  /* Small devices (tablets, 768px and up) */
  margin: auto;
  float: none;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.06);
  position: relative;
  top: -56px;
  min-height: 175px;
  margin-bottom: -16px;
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    width: 80%;
    padding: 24px 22px 27px 20px;
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media only screen and (min-width: 768px) and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    top: -86px;
    padding: 25px 17px 27px 19px;
    margin-bottom: -46px;
  }
}
@media only screen and (min-width: 768px) and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content {
    padding: 25px 41px 27px 40px;
    width: 72%;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.22;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #333;
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-title {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-top: 20px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 24px;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0.1px;
  }
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 13px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .card-body {
    margin-top: 17px;
  }
}
.hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
  margin-top: 19px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 7px;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 9px;
  }
}
@media only screen and (min-width: 1366px) {
  .hero-panel-with-card.hero-panel-contain-card .hero-card-container .hero-card .card-content .read-more {
    margin-top: 19px;
  }
}